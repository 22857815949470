/** *************************************************************************************************
 * This piece of code is required for the third party package 'panzoom' which is used by Preference-Center.
 * The version in use is v9.2.1 and we can test and remove this code once we can upgrade to a newer version
 * that fixes the global issue. To test the functionality, after the code is removed, the user should be
 * able to go into preference center -> profile -> profile picture -> add/edit and should be able to zoom
 * into the selected picture using the slider provided.
 */
if (window['global'] === undefined) {
  window['global'] = window;
}
