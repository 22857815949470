export function addRequestIdleCallback(windowRef) {
  /* !
   * Copyright 2015 Google Inc. All rights reserved.
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
   * or implied. See the License for the specific language governing
   * permissions and limitations under the License.
   */

  /*
   * @see https://developers.google.com/web/updates/2015/08/using-requestidlecallback
   */
  windowRef.requestIdleCallback =
    windowRef.requestIdleCallback || requestIdleCallbackFallback;
  windowRef.cancelIdleCallback =
    windowRef.cancelIdleCallback || cancelIdleCallbackFallback;
}

function requestIdleCallbackFallback(cb) {
  return setTimeout(() => {
    const start = Date.now();
    cb({
      didTimeout: false,
      timeRemaining: () => {
        return Math.max(0, 50 - (Date.now() - start));
      },
    });
  }, 10);
}

function cancelIdleCallbackFallback(id) {
  clearTimeout(id);
}
